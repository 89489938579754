export const ProcedureDetail = (props) => {
  return (
    <div id="procedure-detail">
      <div className="container">
        <div className="section-title text-center center">
          <h2>Procedure Detail</h2>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/about.jpeg"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
